import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import ULanding from '../../Utils/ULanding';
import UContent from '../../Utils/UContent';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import {Editor as ClassicEditor} from 'ckeditor5-custom-build/build/ckeditor';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor.js';
import HeaderAdmin from '../../component/global/HeaderAdmin';

export default function AdminContent() {
 const [searchParams] = useSearchParams()
 const link = searchParams.get('id')
 const token = sessionStorage.getItem('token')
 const [content, setContent] = useState()


 const change = (evt) => {
  var newContent = evt.getData();
  setContent(newContent)
 }

 function createMarkup(html) {
  return {
   __html: DOMPurify.sanitize(html)
  }
 }

 const handleSubmit = () => {
 UContent.upContent(link, content, token, () => { })
   .then((res) => {
    if (res.data.stat > 0) {
     toast.success(res.data.msg, {
      position: toast.POSITION.TOP_RIGHT
     })
    } else {
     toast.error(res.data.msg, {
      position: toast.POSITION.TOP_RIGHT
     })
    }
   })
   .catch((err) => {
    console.log(err);
   })
 }

 const getData = () => {
  ULanding.getContent(link).then((res) => {
   if (res.data[0].content === null) {
    setContent('Isi Disini')
   } else {
    setContent(res.data[0].content)
   }
  })
 }

 function uploadAdapter(loader) {
  return {
   upload: () => {
    return new Promise((resolve, reject) => {
     let formData = new FormData();
     let reader = new FileReader();
     loader.file.then(async (file) => {
      reader.readAsDataURL(file);
      reader.onload = () => {
       resolve({ default: reader.result })
      }
     });
    });
   }
  };
 }

 function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
   return uploadAdapter(loader);
  };
 }

 const config = {
  extraPlugins: [uploadPlugin]
 }

 useEffect(() => {
  getData()
 }, [])
 return (
  <>
   <HeaderAdmin />
   <div className='page-wrapper'>
    <div className='container-fluid'>
     <div className='card'>
      <div className='card-header'>
       <button className='btn btn-block btn-info text-white' onClick={handleSubmit}>Simpan</button>
      </div>
      <div className='card-body'>
       <div className='row'>
        <div className='col-12'>
         <CKEditor
          config={config}
          editor={ClassicEditor}
          data={content}
          onChange={(event, editor) => {
           change(editor)
          }}
         />
        </div>
        <div className='col-12'>
         <div
          className="ck-content"
          dangerouslySetInnerHTML={createMarkup(content)}>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 )
}