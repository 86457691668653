import http from './Common';

class UCarousel {
 listCarousel = (id) => {
  let formData = new FormData();
  formData.append('id', id)
  return http.post('/listCarousel', formData)
 }
 findCarousel = (id, token) => {
  let formData = new FormData();
  formData.append('id', id)
  return http.post('/findCarousel', formData, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer" + token
   }
  })
 }
 addCarousel = (data, token) => {
  return http.post('/addCarousel', data, {
   headers: {
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
   }
  })
 }
 updCarousel = (data, token) => {
  return http.post('/updCarousel', data, {
   headers: {
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
   }
  })
 }
 delCarousel = (data, token) => {
  return http.post('/delCarousel', data, {
   headers: {
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
   }
  })
 }
 upContenImage = (img) => {
  let formData = new FormData();
  formData.append('image', img)
  return http.post('/ContentImageStore', formData, {
   headers: {
    "Content-type": "multipart/form-data;",
    'Accept': 'application/json',
   }
  })
 }
}

export default new UCarousel();