import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import UCarousel from '../../../../Utils/UCarousel';
import ModalCarousel from '../Modal/MCarousel';

export default function TbCarousel(props) {
 const token = sessionStorage.getItem('token')
 const [DataModal, setDataModal] = useState()
 const [modal, setModal] = useState(false)
 const [menu, setMenu] = useState()
 const [feed, setFeedBack] = useState()

 const getData = () => {
  try {
   UCarousel.listCarousel(token).then((res) => {
    setMenu(res.data)
    tableConfig();
   })
  } catch (error) {
   toast.error(error, {
    position: toast.POSITION.TOP_RIGHT
   })
  }
 }

 const tableConfig = () => {
  setTimeout(function () {
   $('.table').DataTable({
    pageLength: 1000,
    processing: true,
    retrieve: true,
    dom: 'Bfrtip',
   });
  }, 1000);
 }

 const handleModal = (e) => {
  setModal(!modal)
  const target = e.currentTarget
  const id = target.getAttribute('data-id')
  const mdl = target.getAttribute('data-modal')
  setDataModal({ id, mdl })
 }

 const feedBack = (fb) => {
  $('.table').DataTable().destroy()
  getData();
  props.feedBack(fb)
  if (fb.stat > 0) {
   toast.success(fb.msg, {
    position: toast.POSITION.TOP_RIGHT
   })
  } else {
   toast.error(fb.msg, {
    position: toast.POSITION.TOP_RIGHT
   })
  }
 }

 useEffect(() => {
  getData()
 }, [])
 return (
  <>
   <ToastContainer pauseOnHover />
   <div class="card">
    <div class="card-header">
     <h3 class="card-title">
      Carousel
      <button type='button' className='btn bg-info' data-modal="add" style={{marginLeft: '20px'}} onClick={(e) => handleModal(e)}>
       <i class="fas fa-plus text-white"></i>
      </button>
     </h3>
    </div>
    <div class="card-body table-responsive p-0" style={{ height: '500px' }}>
     <table class="table table-head-fixed text-nowrap">
      <thead>
       <tr>
        <th>ID</th>
        <th>Title</th>
        <th>Desc</th>
        <th>Img</th>
        <th>Act</th>
       </tr>
      </thead>
      <tbody>
       {menu ? menu.map((row, index) => {
        return (
         <>
          <tr>
           <td>{index + 1}</td>
           <td>{row.title}</td>
           <td>{row.desc}</td>
           <td><img className='d-flex justify-content-center w-100 table-img' src={row.img} /></td>
           <td>
            <button type='button' className='btn bg-warning' data-modal="upd" data-id={row.id} onClick={(e) => handleModal(e)}>
             <i class="fas fa-edit text-white"></i>
            </button>
            <button type='button' className='btn bg-danger' data-modal="del" data-id={row.id} onClick={(e) => handleModal(e)}>
             <i class="fas fa-trash text-white"></i>
            </button>
           </td>
          </tr>
         </>
        )
       }) : null}
      </tbody>
     </table>
    </div>
   </div>
   <Modal show={modal} onHide={() => setModal(false)}>
    <ModalCarousel data={DataModal} feedBack={feedBack} />
   </Modal>
  </>
 )
}