import { ToastContainer, toast } from "react-toastify";

export default function Notif(props){
 if(props.stat > 0){
  toast.success(props.msg, {
   position: toast.POSITION.TOP_RIGHT
  })
 }else{
  toast.error(props.msg, {
   position: toast.POSITION.TOP_RIGHT
  })

 }
 
}