import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ULanding from '../../Utils/ULanding'

export default function SidebarUser() {
 const [Menu, setMenu] = useState()
 const navigate = useNavigate
 const getMenu = async () => {
  try {
   ULanding.getAllMenu().then((res) => {
    setMenu(res.data)
   })
  } catch (error) {
   console.log(error);
  }
 }

 useEffect(() => {
  getMenu()
 }, [])
 return (
  <>
   <div class="container-lg">
   <ul class="navbar-nav me-auto my-lg-0 flex-row justify-content-between" style={{}}>
     <li class="nav-item btn-menu">
      <a class="nav-link  text-white active btn-text" aria-current="page" href="/">Home</a>
     </li>
     {
      Menu ? Menu.map((row) => {
       if (row.subMenu.length > 0) {
        return (
         <li class="nav-item dropdown btn-menu">
          <a class="nav-link dropdown-toggle text-white  btn-text" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           {row.menu}
          </a>
          <ul class="dropdown-menu btn-text" aria-labelledby="navbarDropdown">
           {row.subMenu.map((rows) => {
            return (
             <li><a class="dropdown-item btn-text" href={'/content?id=' + rows.link + '&mn=' + row.link + '&nm=' + rows.subMenu}>{rows.subMenu}</a></li>
            )
           })}
          </ul>
         </li>
        )
       } else {
        return (
         <>
          <li class="nav-item btn-menu">
           <a class="nav-link text-white btn-text" href={'/content?id=' + row.link}>{row.menu}</a>
          </li>
         </>
        )
       }
      }
      ) : null}
    </ul>
   </div>
  </>
 )
}