import $ from 'jquery'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ContentPage from './pages/ContentPage';
import Login from './pages/LoginPage';
import DashboardPage from './pages/admin/DashboardPage';
import RegisterPage from './pages/RegisterPage';
import AdminCarousel from './pages/admin/AdminCarousel';
import RegisterScreen from './pages/admin/RegisterScreen';
import MenuScreen from './pages/admin/MenuScreen';
import SubMenuScreen from './pages/admin/SubMenuScreen';
import AdminContent from './pages/admin/Content';
import NewsScreen from './pages/admin/NewsScreen';

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
			  <Route path="/" element={<LandingPage />} />
				<Route path="/content" element={<ContentPage />} />
				<Route path="/register" element={<RegisterPage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/dashboard" element={<DashboardPage />} />
				<Route path="/AdminCarousel" element={<AdminCarousel />} />
				<Route path="/AdminRegister" element={<RegisterScreen />} />
				<Route path="/Menu" element={<MenuScreen />} />
				<Route path="/SubMenu" element={<SubMenuScreen />} />
				<Route path="/Admincontent" element={<AdminContent />} />
				<Route path="/AdminNews" element={<NewsScreen />} />
				
			</Routes>
		</BrowserRouter>
	)
}