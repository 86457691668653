import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import UCarousel from '../../../../Utils/UCarousel';
export default function ModalCarousel(props) {
 const token = sessionStorage.getItem('token');
 const mdl = props.data.mdl;
 const id = props.data.id;
 const [data, setData] = useState()
 const [carImage, setCarImage] = useState()
 const [Field, setField] = useState({})

 const cek = () => {
  if (id > 0) {
   UCarousel.findCarousel(id, token).then((res) => {
    setData(res.data)
    setCarImage(res.data.img)
    Field['title'] = res.data.title;
    Field['desc'] = res.data.desc;
    Field['img'] = res.data.img;
   })
  }
 }
 const handleSubmit = (e) => {
  e.preventDefault()
  if (mdl === 'add') {
   UCarousel.addCarousel(Field, token).then((res) => {
    props.feedBack(res.data)
   }).catch((error) => {
    props.feedBack({ stat: 0, msg: error })
   })
  } else if (mdl === 'upd') {
   Field['id'] = id;
   UCarousel.updCarousel(Field, token).then((res) => {
    props.feedBack(res.data)
   }).catch((error) => {
    props.feedBack({ stat: 0, msg: error })
   })
  } else if (mdl === 'del') {
   Field['id'] = id;
   UCarousel.delCarousel(Field, token).then((res) => {
    props.feedBack(res.data)
    console.log(res.data);
   }).catch((error) => {
    props.feedBack({ stat: 0, msg: error })
   })
  }

 }

 const handleChange = async (e) => {
  var target = e.target;
  var name = target.name;
  var value = target.value;
  Field[name] = target.value;
 }

 const fileToBlob = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
   resolve(event.target.result)
  };
  reader.readAsDataURL(file)
 })

 const handleImage = (file) => {
  fileToBlob(file)
   .then(carImage => {
    setCarImage(false)
    setTimeout(() => {
     setCarImage(carImage)
     Field['img'] = carImage
    }, 1000);
   })
 }
 // const src = URL.createObjectURL(loader)
 // setCarImage(src)
 // Field['img'] = src


 useEffect(() => {
  cek()
 }, [])

 return (
  <>
   <form onSubmit={handleSubmit}>
    <Modal.Header closeButton>
     <Modal.Title>Modal {mdl}</Modal.Title>
    </Modal.Header>
    {mdl === 'add' || mdl === 'upd' ?
     (
      <>
       <Modal.Body>
        <div class="row">
         <div class="col-sm-12">
          <div class="form-group">
           <label>Title</label>
           <input type="text" name="title" defaultValue={data ? data.title : null} class="form-control" onChange={(e) => handleChange(e)} />
          </div>
         </div>
         {/* <div class="col-sm-12">
          <div class="form-group">
           <label>Captions</label>
           <textarea name='desc' className='form-control' defaultValue={data ? data.desc : null} onChange={(e) => handleChange(e)} />
          </div>
         </div> */}
         <div class="col-sm-12">
          <div class="form-group">
           <label>image</label>
           <input type="file" name="img" defaultValue={data ? data.img : null} class="form-control" onChange={(e) => handleImage(e.target.files[0])} />
          </div>
         </div>
         <div class="col-sm-12 mt-2">
          {carImage ? <img className='d-block w-100 carousel-img' src={carImage} /> : null}
         </div>
        </div>
       </Modal.Body>
      </>
     )
     : (
      <>
       <Modal.Body>
        <div class="col-12">
         <p>Yakin akan delete ?</p>
        </div>
       </Modal.Body>
      </>
     )}
    <Modal.Footer>
     <button type='submit' className='btn bg-info' onClick={(e) => handleSubmit(e)}>
      <i class="fas fa-plus text-white"> Save</i>
     </button>
    </Modal.Footer>
   </form>
  </>
 )
}