import http from './Common.jsx';

class ULogin {
 Login = (Field) => {
  return http.post('/login', Field)
 }
 refresh = (token) => {
  return http.post('/refresh', {
   headers: {
    "Accept": "application/json",
    "Authorization": "Bearer " +token
   }
  })
 }
}
export default new ULogin();