import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor.js';
import DOMPurify from 'dompurify';
import UNews from '../../../../Utils/UNews';

export default function ModalNews(props) {
	const token = sessionStorage.getItem('token');
	const mdl = props.data.mdl;
	const id = props.data.id;
	const [data, setData] = useState()
	const [max, setMax] = useState()
	const [Field, setField] = useState({})
	const [content, setContent] = useState()

	const cek = () => {
		if (id > 0) {
			try {
				UNews.findNews(id, token).then((res) => {
					setData(res.data)
					Field['date'] = res.data.date;
					Field['activity'] = res.data.activity;
					setContent(res.data.activity)
					Field['locations'] = res.data.locations;
					Field['status'] = res.data.status;
				})
			} catch (error) {
				console.log(error);
				// props.feedBack({stat: 0, msg: error})
			}
		} else {
			setMax(props.data.max)
		}
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		console.log(Field);
		if (mdl === 'add') {
			Field['urut'] = props.data.max;
			UNews.addNews(Field, token).then((res) => {
				props.feedBack(res.data)
			}).catch((error) => {
				props.feedBack({ stat: 0, msg: error })
			})
		} else if (mdl === 'upd') {
			Field['id'] = id;
			UNews.updNews(Field, token).then((res) => {
				props.feedBack(res.data)
				console.log(res.data);
			}).catch((error) => {
				props.feedBack({ stat: 0, msg: error })
				console.log(error);
			})
		} else if (mdl === 'del') {
			Field['id'] = id;
			UNews.delNews(Field, token).then((res) => {
				props.feedBack(res.data)
				console.log(res.data);
			}).catch((error) => {
				props.feedBack({ stat: 0, msg: error })
			})
		}

	}

	const handleChange = async (e) => {
		var target = e.target;
		var name = target.name;
		var value = target.value;
		Field[name] = target.value;
	}

	const change = (evt) => {
		var newContent = evt.getData();
		setContent(newContent)
		Field['activity'] = newContent
	}

	function createMarkup(html) {
		return {
			__html: DOMPurify.sanitize(html)
		}
	}

	function uploadAdapter(loader) {
		console.log(loader);
		return {
			upload: () => {
				return new Promise((resolve, reject) => {
					let formData = new FormData();
					let reader = new FileReader();
					loader.file.then(async (file) => {
						reader.readAsDataURL(file);
						reader.onload = () => {
							resolve({ default: reader.result })
						}
					});
				});
			}
		};
	}

	function uploadPlugin(editor) {
		editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
			return uploadAdapter(loader);
		};
	}

	const config = {
		extraPlugins: [uploadPlugin]
	}

	useEffect(() => {
		cek()
	}, [])

	return (
		<>
			<form onSubmit={handleSubmit}>
				<div className='card card-body'>
					<Modal.Header closeButton>
						<Modal.Title>Modal {mdl}</Modal.Title>
					</Modal.Header>
					{mdl === 'add' || mdl === 'upd' ?
						(
							<>
								<Modal.Body>
									<div class="row">
										<div class="col-sm-12">
											<div class="form-group">
												<label>Date</label>
												<input type="date" name="date" defaultValue={data ? data.date : null} class="form-control" onChange={(e) => handleChange(e)} />
											</div>
										</div>
										<div class="col-sm-12">
											<div class="form-group">
												<label>Activity</label>
												<CKEditor
													config={config}
													editor={ClassicEditor}
													data={content}
													onChange={(event, editor) => {
														change(editor)
													}}
												/>
											</div>
										</div>
										<div class="col-sm-12">
											<div class="form-group">
												<label>Locations</label>
												<input type='text' name="locations" defaultValue={data ? data.locations : null} class="form-control" onChange={(e) => handleChange(e)} />
											</div>
										</div>
										<div class="col-sm-10">
											<div class="form-group">
												<label>Status</label>
												<input type="text" name="status" defaultValue={data ? data.status : max} class="form-control" onChange={(e) => handleChange(e)} />
											</div>
										</div>
										<div class="col-sm-2">
											<div class="form-group">
												<label>Urutan</label>
												<input type="text" name="urut" defaultValue={data ? data.urut : max} class="form-control" onChange={(e) => handleChange(e)} />
											</div>
										</div>
									</div>
								</Modal.Body>
							</>
						)
						: (
							<>
								<Modal.Body>
									<div class="col-12">
										<p>Yakin akan delete ?</p>
									</div>
								</Modal.Body>
							</>
						)}
					<Modal.Footer>
						<button type='submit' className='btn bg-info btn-block' onClick={(e) => handleSubmit(e)}>
							<i class="fas fa-plus text-white"> Save</i>
						</button>
					</Modal.Footer>
				</div>
			</form>
		</>
	)
}