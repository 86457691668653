import React, { useState, useEffect } from 'react';
import Header from '../../component/global/Header';
import SidebarAdmin from '../../component/global/admin/SidebarAdmin';
import CarouselComp from '../../component/global/Carousel';
import TbCarousel from '../../component/global/admin/Table/TbCarousel';
import HeaderAdmin from '../../component/global/HeaderAdmin';

export default function AdminCarousel(props) {
	const [show, setShow] = useState(true)
 
	const feedBack = (feed) => {
		setShow('')
		setTimeout(() => {
			setShow(true)
		}, 1000);
	}
 useEffect(() => {
  // getData()
 }, [])
 return (
  <>
   <HeaderAdmin />
   <div className='page-wrapper'>
    <div className='container-fluid'>
     <div className='card mb-2'>
      <div className='card-body'>
       {show ? <CarouselComp /> : null}
      </div>
     </div>
     <div className='card'>
      <div className='card-body'>
       <TbCarousel feedBack={feedBack}/>
      </div>
     </div>
    </div>
   </div>
  </>
 )
}