import moment from 'moment';
import React, { useState, useEffect } from 'react';
import UNews from '../../Utils/UNews';
import $ from 'jquery';
import DOMPurify from 'dompurify';
import '@ckeditor/ckeditor5-table/theme/table.css'
import { Modal } from 'react-bootstrap';
import ModalUserNews from './admin/Modal/MUserNews';

export default function NewsComp() {
 const token = sessionStorage.getItem('token')
 const [news, setNews] = useState()
 const [newsId, setNewsId] = useState()
 const [show, setShow] = useState(false);
 const handleClose = () => setShow(false);

 function createMarkup(html) {
  return {
   __html: DOMPurify.sanitize(html)
  }
 }

 const getData = () => {
  try {
   UNews.listNews(token).then((res) => {
    setNews(res.data)
   })
  } catch (error) {
   console.log(error);
  }
 }

 const handleModal = (e, data) => {
  setShow(true)
  setNewsId(data)
 }

 const feedBack = (data) => {
   if(data === 'close'){
     handleClose()
  }
 }

 useEffect(() => {
  getData()
 }, [])
 return (
  <>
   <div className='row'>
    <div className='container-fluid'>
     <div className='card mt-2'>
      <div className='card-header header-content'>
       <h3 style={{ textShadow: '1px 1px #f2f7f8', color: '#fff' }}>News</h3>
      </div>
      <div className='card-body'>
       <div class="table-responsive">
        <table class="table table-bordered no-wrap" style={{ fontSize: 'small' }}>
         <thead>
          <tr>
           <th>Date</th>
           <th>News`</th>
           <th>Read More</th>
          </tr>
         </thead>
         <tbody>
          {news ? news.map((row, index) => {
           return (
            <>
             <tr>
              <td width={"5%"}>{row.date}</td>
              <td width={"20%"}>{row.title}<br />{row.locations}</td>
              <td width={"5%"}>
               <button className='btn-menu text-white' onClick={(e) => handleModal(e, row.id)}>
                Read More
               </button>
              </td>
             </tr>
            </>
           )
          })
           : null}
         </tbody>
        </table>
        <Modal show={show} onHide={handleClose}>
          <ModalUserNews feedBack={feedBack} id={newsId} />
         {/* <Modal.Header className='bg-topbar' closeButton>{newsId}</Modal.Header>
         <Modal.Body>
         </Modal.Body> */}
        </Modal>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 )
}