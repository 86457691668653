import React from 'react'
import HeaderAdmin from '../../component/global/HeaderAdmin'

export default function DashboardPage() {
 return (
  <div className='main-wrapper'>
   <HeaderAdmin />
   <div className='page-wrapper'>
    <div className='container-fluid'>
    </div>
   </div>
  </div>
 )
}