import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import ULogin from '../Utils/ULogin';

export default function Login() {
	const navigate = useNavigate();
	const [Field, setField] = useState({})
	const [Result, setResult] = useState({ stat: '' })

	const handeleChange = async (e) => {
		var target = e.target;
		var name = target.name;
		var value = target.value;
		Field[name] = target.value;
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		ULogin.Login(Field, () => { })
			.then((res) => {
				setResult(res.data)
				if (res.data.stat > 0) {
					sessionStorage.setItem('user', res.data.user)
					sessionStorage.setItem('token', res.data.token)
					setTimeout(() => {
						navigate('/dashboard')
					}, 1000);
				} else {
					setTimeout(() => {
						navigate('/login')
					}, 3000);
				}
			})
	}

	const Notif = () => {
		console.log(Result);
		if (Result.stat > 0) {
			return (
				<>
					<div className='card card-inverse bg-success'>
						<h3 className='card-title text-white'>{Result.msg}</h3>
					</div>
				</>
			)
		} else if (Result.stat === "0") {
			return (
				<>
					<div className='card card-inverse bg-danger'>
						<h3 className='card-title text-white'>{Result.msg}</h3>
					</div>
				</>
			)

		} else {
			return (
				<>
				</>
			)
		}
	}


	useEffect(() => {
		// addclass()
	}, [])
	return (
		<>
			<section style={{ display: 'block' }}>
				<div className='login-register'>
					<div className='login-box card'>
						<div className='card-body'>
							<form onSubmit={handleSubmit} method="post" className='form-horizontal form-material'>
								<h3 className='box-title mb-3 text-center'>Login</h3>
								<div className='form-group mb-2'>
									<div className='col-xs-12'>
										<input type="text" name='email' onChange={(e) => { handeleChange(e) }} className="form-control" placeholder="Email" />
									</div>
								</div>
								<div className='form-group mb-2'>
									<div className='col-xs-12'>
										<input type="password" name='password' onChange={(e) => { handeleChange(e) }} class="form-control" placeholder="Password" />
									</div>
								</div>
								<div className='form-group text-center mt-3'>
									<div className='col-xs-12'>
										<button class="btn bg-success text-white btn-lg" style={{ width: '100%' }} type="submit">Log In</button>
									</div>
								</div>
								<div className='form-group text-center mt-3'>
									<div className='col-xs-12'>
										{Result ? <Notif /> : null}
										{/* {Result.stat > 0 ? 
          <div className='card card-inverse bg-success'>
           <h3>{Result.msg}</h3>
          </div> 
          : 
          
          // <div className='card card-inverse bg-danger'>
          //  <h3 className='text-white text-center'>Must Be Filled</h3>
          // </div>
          } */}
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}