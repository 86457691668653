import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import $ from 'jquery';
import moment from 'moment/moment.js';
import ModalNews from '../Modal/MNews.jsx';
import DOMPurify from 'dompurify';
import UNews from '../../../../Utils/UNews.jsx';

export default function TbNews() {
 const token = sessionStorage.getItem('token')
 const [DataModal, setDataModal] = useState()
 const [modal, setModal] = useState(false)
 const [menu, setMenu] = useState()
 const [feed, setFeedBack] = useState()
 const [fullscreen, setFullscreen] = useState(true);

 const getData = () => {
  try {
   UNews.listNews(token).then((res) => {
    setMenu(res.data)
   })
  } catch (error) {
   toast.error(error, {
    position: toast.POSITION.TOP_RIGHT
   })
  }
 }

 const handleModal = (e) => {
  setModal(!modal)
  const target = e.currentTarget
  const id = target.getAttribute('data-id')
  const mdl = target.getAttribute('data-modal')
  setDataModal({ id, mdl })
 }

 const feedBack = (fb) => {
  getData();
  if (fb.stat > 0) {
   toast.success(fb.msg, {
    position: toast.POSITION.TOP_RIGHT
   })
  } else {
   toast.error(fb.msg, {
    position: toast.POSITION.TOP_RIGHT
   })
  }
 }

 function createMarkup(html) {
  return {
   __html: DOMPurify.sanitize(html)
  }
 }

 useEffect(() => {
  getData()
 }, [])
 return (
  <>
   <ToastContainer pauseOnHover />
   <div class="card">
    <div class="card-header">
     <h3 class="card-title">
      News
      <button type='button' className='btn bg-info' data-modal="add" style={{ marginLeft: '20px' }} onClick={(e) => handleModal(e)}>
       <i class="fas fa-plus text-white"></i>
      </button>
     </h3>
    </div>
    <div class="card-body p-0" style={{ height: '500px', fontSize: 'small' }}>
     <table class="table">
      <thead>
       <tr>
        <th>No</th>
        <th>Activity</th>
        <th>Act</th>
       </tr>
      </thead>
      <tbody>
       {menu ? menu.map((row, index) => {
        return (
         <>
          <tr>
           <td>{index + 1}</td>
           <td>
            <div
             className="ck-content"
             dangerouslySetInnerHTML={createMarkup(row.activity)}>
            </div>
           </td>
           <td>
            <button type='button' className='btn btn-sm bg-warning' data-modal="upd" data-id={row.id} onClick={(e) => handleModal(e)}>
             <i class="fas fa-edit text-white"></i>
            </button>
            <button type='button' className='btn btn-sm bg-danger' data-modal="del" data-id={row.id} onClick={(e) => handleModal(e)}>
             <i class="fas fa-trash text-white"></i>
            </button>
           </td>
          </tr>
         </>
        )
       }) : null}
      </tbody>
     </table>
    </div>
   </div>
   <Modal fullscreen={fullscreen} show={modal} onHide={(e) => setModal(false)}>
    <ModalNews data={DataModal} feedBack={feedBack} />
   </Modal>
  </>
 )
}