import http from './Common';

class UContent {
 upContent = (link, content, token) => {
  let formData = new FormData();
  formData.append('link', link)
  formData.append('content', content)
  console.log(content);
  return http.post('/upContent', formData, {
   headers: {
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
   }
  })
 }
 upContenImage = (img) => {
  let formData = new FormData();
  formData.append('image', img)
  console.log(img);
  return http.post('/ContentImageStore', formData, {
   headers: {
    "Content-type": "multipart/form-data;",
    'Accept': 'application/json',
   }
  })
 }
}

export default new UContent();