import http from './Common.jsx';

class ULanding {
 getMenu = () => {
  return http.get('/getMenu')
 }

 findMenu = (id, token) => {
  let formData = new FormData();
  formData.append('id', id)
  return http.post('/findMenu', formData, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer" + token
   }
  })
 }

 addMenu = (field, token) => {
  return http.post('/addMenu', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }
 updMenu = (field, token) => {
  // console.log(field);
  return http.post('/updMenu', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }
 delMenu = (field, token) => {
  console.log(field);
  return http.post('/delMenu', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }

 getAllMenu = () => {
  return http.get('/getAllMenu')
 }

 getSubMenu = (id) => {
  let formData = new FormData();
  formData.append('id', id)
  return http.post('/getSubMenu', formData)
 }
 getAllSubMenu = (token) => {
  return http.post('/getAllSubMenu', {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }

 findSubMenu = (id, token) => {
  let formData = new FormData();
  formData.append('id', id)
  return http.post('/findSubMenu', formData, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer" + token
   }
  })
 }

 addSubMenu = (field, token) => {
  console.log(field);
  return http.post('/addSubMenu', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }
 updSubMenu = (field, token) => {
  return http.post('/updSubMenu', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }
 delSubMenu = (field, token) => {
  console.log(field);
  return http.post('/delSubMenu', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }

 getContent = (id) => {
  let formData = new FormData();
  formData.append('link', id)
  return http.post('/getContent', formData)
 }
}
export default new ULanding();