import React from 'react'
import { ToastContainer } from 'react-toastify'
import SidebarUser from './SidebarUser'
import SidebarAdmin from './admin/SidebarAdmin'

export default function Header() {
  return (
    <>
      <ToastContainer />
      <header class="topbar fixed-top">
        <div className='container-lg'>
          <div className='row'>
            <div className='col-5 align-self-center'>
              <div class="navbar-header">
                <a class="navbar-brand" href="/">
                  <b>
                    <img src={process.env.PUBLIC_URL + 'asset/dist/img/logo.png'} alt="homepage" class="dark-logo" />
                  </b>
                </a>
              </div>
            </div>
            <div className='col-7 align-self-center'>
              <h3 className='logo-text text-end align-self-center'>
                <a className='logo-text' aria-current="page" href="/login">
                  Masayuki
                </a>
              </h3>
            </div>
          </div>
          <div className='row'>
            <SidebarUser />
          </div>
        </div>
        {/* <nav class="navbar top-navbar navbar-expand-md navbar-light">
          <div className='container-lg'>
            <div class="navbar-header">
              <a class="navbar-brand" href="/">
                <b>
                  <img src={process.env.PUBLIC_URL + 'asset/dist/img/logo.png'} alt="homepage" class="dark-logo" />
                </b>
              </a>
            </div>
            <div class="navbar-collapse justify-content-end">
              <ul className='navbar-nav my-lg-0'>
                <li className='nav-item'>
                  <a className='logo-text' aria-current="page" href="/login">
                    Masayuki
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav> */}
      </header>
    </>
  )
}