import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import UCarousel from '../../Utils/UCarousel';

export default function CarouselComp() {
 const [data, setData] = useState()

 const getData = async () => {
  UCarousel.listCarousel().then((res) => {
   setData(res.data)
   console.log(res.data);
  })
 }

 useEffect(() => {
  getData()
 }, [])

 return (
  <div className='carousel'>
   <Carousel>
    {data ? data.map((review, index) => (
     <Carousel.Item key={index}>
      <img
       className="d-block w-100 carousel-img"
       src={review.img}
       alt={review.img}
      />
      <Carousel.Caption>
       <h3>{review.title}</h3>
      </Carousel.Caption>
     </Carousel.Item>
    )) : null}
   </Carousel>
  </div>
 )
}