import http from './Common.jsx';

class URegister {
 getRegister = (token) => {
  return http.post('/getRegister', {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer" + token
   }
  })
 }

 findMenu = (id, token) => {
  let formData = new FormData();
  formData.append('id', id)
  return http.post('/findMenu', formData, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer" + token
   }
  })
 }

 addRegister = (field, token) => {
  return http.post('/addRegister', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }
 updRegister = (field, token) => {
  // console.log(field);
  return http.post('/updRegister', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }
 delRegister = (field, token) => {
  console.log(field);
  return http.post('/delRegister', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }
}
export default new URegister();