import { ToastContainer } from "react-toastify";
import Header from "../component/global/Header";
import { Button, Card, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { useState } from "react";
import URegister from "../Utils/URegister";
import Notif from "../component/global/Notifi";
import Loading from "../component/global/Loading";
import { useNavigate } from "react-router-dom";

export default function RegisterPage() {
 const navigate = useNavigate()
 const [Fields, setFields] = useState({})
 const [Show, setShow] = useState(true)

 const handleChange = (a, b) => {
  Fields[a] = b
 }

 const handleSubmit = () => {
  setShow(false)
  URegister.addRegister(Fields)
   .then((hs) => {
    Notif(hs.data)
    setShow(true)
    setTimeout(() => {
     navigate('/')
    }, 1000);
   })
   .catch((err) => {
    setShow(true)
    Notif({ stat: 0, msg: err })
    setTimeout(() => {
     navigate('/')
    }, 1000);
   })
 }
 return (<>
  <Header />
  <div className='page-wrapper'>
   <Container>
    <Card>
     <Card.Body>
      <Row>
       <Col lg={6} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Email"
         className="mb-3"
        >
         <Form.Control type="email" onChange={(e) => handleChange('email', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={6} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Nama Lengkap"
         className="mb-3"
        >
         <Form.Control type="text" placeholder="name@example.com" onChange={(e) => handleChange('nama', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={2} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Tanggal Lahir"
         className="mb-3"
        >
         <Form.Control type="date" onChange={(e) => handleChange('dob', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={2} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Telepon"
         className="mb-3"
        >
         <Form.Control type="number" onChange={(e) => handleChange('tlp', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={2} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Usia"
         className="mb-3"
        >
         <Form.Control type="number" onChange={(e) => handleChange('age', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={3} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Jenis Kelamin"
         className="mb-3"
        >
         <Form.Select onChange={(e) => handleChange('sex', e.target.value)}>
          <option selected>Pilih</option>
          <option>Laki-Laki</option>
          <option>Perempuan</option>
         </Form.Select>
        </FloatingLabel>
       </Col>
       <Col lg={3} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Status Menikah"
         className="mb-3"
        >
         <Form.Select onChange={(e) => handleChange('nikah', e.target.value)}>
          <option selected>Pilih</option>
          <option>Menikah</option>
          <option>Lajang</option>
         </Form.Select>
        </FloatingLabel>
       </Col>
       <Col lg={3} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Tinggi Badan (cm)"
         className="mb-3"
        >
         <Form.Control type="number" onChange={(e) => handleChange('tinggi', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={3} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Berat Badan (kg)"
         className="mb-3"
        >
         <Form.Control type="number" onChange={(e) => handleChange('berat', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={6} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Alamat"
         className="mb-3"
        >
         <Form.Control type="text" onChange={(e) => handleChange('alamat', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={2} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="RT"
         className="mb-3"
        >
         <Form.Control type="text" onChange={(e) => handleChange('rt', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={2} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="RW"
         className="mb-3"
        >
         <Form.Control type="text" onChange={(e) => handleChange('rw', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={4} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Kecamatan"
         className="mb-3"
        >
         <Form.Control type="text" onChange={(e) => handleChange('kec', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={4} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Kabupaten / Kota"
         className="mb-3"
        >
         <Form.Control type="text" onChange={(e) => handleChange('kab', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={4} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Pendidikan Terakhir"
         className="mb-3"
        >
         <Form.Select onChange={(e) => handleChange('didik', e.target.value)}>
          <option>SMP</option>
          <option>SMA</option>
          <option>D3</option>
          <option>D4/S1</option>
          <option>S2</option>
         </Form.Select>
        </FloatingLabel>
       </Col>
       <Col lg={8} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Pekerjaan"
         className="mb-3"
        >
         <Form.Control type="text" onChange={(e) => handleChange('perkerjaan', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={6} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Sertifikat Bahasa Jepang"
         className="mb-3"
        >
         <Form.Select onChange={(e) => handleChange('cert', e.target.value)}>
          <option selected>Pilih Sertifikat Bahasa Jepang</option>
          <option>N3</option>
          <option>N4</option>
          <option>N5</option>
          <option>Tidak Ada</option>
         </Form.Select>
        </FloatingLabel>
       </Col>
       <Col lg={6} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Program yang akan Diikuti"
         className="mb-3"
        >
         <Form.Select onChange={(e) => handleChange('program', e.target.value)}>
          <option selected>Pilih Program yang akan Diikuti</option>
          <option>JISSHUSEI</option>
          <option>SSW/TOKUTEI GINO</option>
          <option>GAKKOU</option>
         </Form.Select>
        </FloatingLabel>
       </Col>
       <Col lg={4} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Ukuran Baju"
         className="mb-3"
        >
         <Form.Select onChange={(e) => handleChange('baju', e.target.value)}>
          <option>S</option>
          <option>M</option>
          <option>L</option>
          <option>XL</option>
          <option>Lainnya</option>
         </Form.Select>
        </FloatingLabel>
       </Col>
       <Col lg={4} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Lainnya"
         className="mb-3"
        >
         <Form.Control type="text" onChange={(e) => handleChange('baju', e.target.value)} />
        </FloatingLabel>
       </Col>
       <Col lg={4} sm={12}>
        <FloatingLabel
         controlId="floatingInput"
         label="Pengalaman Kerja Jisshushei (Magang) di Jepang"
         className="mb-3"
        >
         <Form.Select onChange={(e) => handleChange('pengalaman', e.target.value)}>
          <option selected>Pilih</option>
          <option>Ada</option>
          <option>Tidak Ada</option>
         </Form.Select>
        </FloatingLabel>
       </Col>
      </Row>
      <Row>
       <div className="d-grid gap-2">
        {Show === true ? (<>
         <Button variant="info" size="md" onClick={handleSubmit}>
          <i class="fas fa-plus text-white"> Save</i>
         </Button>
        </>)
         :
         (<>
          <div className="text-center">
           <Loading />
          </div>
         </>)
        }
       </div>
      </Row>
     </Card.Body>
    </Card>
   </Container>
  </div>
 </>)
}