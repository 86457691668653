import React from 'react'

export default function Footer() {
 return (
  <>
   <footer class="footer fixed-bottom">
    <div className='container-lg'>
     <div className='row text-white'>
      <p className='mb-0'>Address</p>
      <p className='mb-0'>Perumnas Bumi Teluk Jambe, Jl. Baladewa III, Blok PF No.16, Sukaluyu, Telukjambe Timur, Karawang, Jawa Barat 41361</p>
     </div>
    </div>
   </footer>
  </>
 )
}