import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ULanding from '../../../../Utils/ULanding';
import URegister from '../../../../Utils/URegister';

export default function ModalRegister(props) {
 const token = sessionStorage.getItem('token');
 const mdl = props.data.mdl;
 const id = props.data.id;
 const [Field, setField] = useState({})

 const handleSubmit = (e) => {
  e.preventDefault()
  Field['id'] = id;
  URegister.delRegister(Field, token).then((res) => {
   props.feedBack(res.data)
  }).catch((error) => {
   props.feedBack({ stat: 0, msg: error })
  })
 }

 return (
  <>
   <Modal.Header closeButton>
    <Modal.Title>Modal {mdl}</Modal.Title>
   </Modal.Header>
   <Modal.Body>
    <div class="col-12">
     <p>Yakin akan delete ?</p>
    </div>
   </Modal.Body>
   <Modal.Footer>
    <button type='submit' className='btn bg-info' onClick={(e) => handleSubmit(e)}>
     <i class="fas fa-plus text-white"> Save</i>
    </button>
   </Modal.Footer>
  </>
 )
}