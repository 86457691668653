import http from './Common.jsx';

class UNews {
 listNews = (token) => {
  return http.post('/listNews', {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer" + token
   }
  })
 }

 findNews = (id, token) => {
  let formData = new FormData();
  formData.append('id', id)
  return http.post('/findNews', formData, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer" + token
   }
  })
 }

 addNews = (field, token) => {
  return http.post('/addNews', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }
 updNews = (field, token) => {
  console.log(field);
  return http.post('/updNews', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }
 delNews = (field, token) => {
  console.log(field);
  return http.post('/delNews', field, {
   headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
   }
  })
 }
}
export default new UNews();