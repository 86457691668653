import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import moment from 'moment/moment.js';
import URegister from '../../../../Utils/URegister';
import ModalMenu from '../Modal/MMenu';
import ModalRegister from '../Modal/MRegister';

export default function TbRegister() {
 const token = sessionStorage.getItem('token')
 const [DataModal, setDataModal] = useState()
 const [modal, setModal] = useState(false)
 const [menu, setMenu] = useState()
 const [feed, setFeedBack] = useState()

 const getData = () => {
  try {
   URegister.getRegister(token).then((res) => {
    setMenu(res.data)
    tableConfig();
   })
  } catch (error) {
   toast.error(error, {
    position: toast.POSITION.TOP_RIGHT
   })
  }
 }

 const tableConfig = () => {
  setTimeout(function () {
   $('.table').DataTable({
    pageLength: 10,
    processing: true,
    retrieve: true,
    dom: 'Bfrtip',
    order: [[0, 'desc']],
    buttons: [
     {
      extend: "pageLength",
      class: "btn btn-primary",
     },
     'copy', 'csv', 'excel', 'pdf', 'print',
    ],
   });
  }, 1000);
 }

 const handleModal = (e) => {
  setModal(!modal)
  const target = e.currentTarget
  const id = target.getAttribute('data-id')
  const mdl = target.getAttribute('data-modal')
  setDataModal({ id, mdl })
 }

 const feedBack = (fb) => {
  $('.table').DataTable().destroy()
  getData();
  if (fb.stat > 0) {
   toast.success(fb.msg, {
    position: toast.POSITION.TOP_RIGHT
   })
  } else {
   toast.error(fb.msg, {
    position: toast.POSITION.TOP_RIGHT
   })
  }
 }

 useEffect(() => {
  getData()
 }, [])
 return (
  <>
   <ToastContainer pauseOnHover />
   <div class="card">
    <div class="card-header">
     <h3 class="card-title">
      Register
      {/* <button type='button' className='btn bg-info' data-modal="add" style={{ marginLeft: '20px' }} onClick={(e) => handleModal(e)}>
       <i class="fas fa-plus text-white"></i>
      </button> */}
     </h3>
    </div>
    <div class="card-body table-responsive p-0" style={{ height: '500px' }}>
     <table class="table table-head-fixed text-nowrap">
      <thead>
       <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Email</th>
        <th>Nama</th>
        <th>Tlp</th>
        <th>Tanggal Lahir</th>
        <th>Usia</th>
        <th>Jenis Kelamin</th>
        <th>Status Menikah</th>
        <th>Tinggi Badan</th>
        <th>Berat Badan</th>
        <th>Alamat</th>
        <th>RT</th>
        <th>RW</th>
        <th>Kecamatan</th>
        <th>Kabupaten/Kota</th>
        <th>Pendidikan</th>
        <th>Pekerjaan</th>
        <th>Sertifikat</th>
        <th>Ukuran Baju</th>
        <th>Pengalaman</th>
        <th>Act</th>
       </tr>
      </thead>
      <tbody>
       {menu ? menu.map((row, index) => {
        console.log(row);
        return (
         <>
          <tr>
           <td>{index + 1}</td>
           <td>{moment(row.created_at).format('DD-MM-YYYY')}</td>
           <td>{row.email}</td>
           <td>{row.nama}</td>
           <td>{row.tlp}</td>
           <td>{moment(row.dob).format('DD-MM-YYYY')}</td>
           <td>{row.age}</td>
           <td>{row.sex}</td>
           <td>{row.nikah}</td>
           <td>{row.tinggi}</td>
           <td>{row.berat}</td>
           <td>{row.alamat}</td>
           <td>{row.rt}</td>
           <td>{row.rw}</td>
           <td>{row.kec}</td>
           <td>{row.kab}</td>
           <td>{row.didik}</td>
           <td>{row.perkerjaan}</td>
           <td>{row.cert}</td>
           <td>{row.baju}</td>
           <td>{row.pengalaman}</td>
           <td>
            <button type='button' className='btn bg-danger' data-modal="del" data-id={row.id} onClick={(e) => handleModal(e)}>
             <i class="fas fa-trash text-white"></i>
            </button>
           </td>
          </tr>
         </>
        )
       }) : null}
      </tbody>
     </table>
    </div>
   </div>
   <Modal show={modal} onHide={() => setModal(false)}>
    <ModalRegister data={DataModal} feedBack={feedBack} />
   </Modal>
  </>
 )
}