import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import ULanding from '../../../../Utils/ULanding';

export default function ModalSubMenu(props) {
 const token = sessionStorage.getItem('token');
 const mdl = props.data.mdl;
 const id = props.data.id;
 const [data, setData] = useState()
 const [Field, setField] = useState({})
 const [opt, setOpt] = useState([])
 const [devOpt, setDevOpt] = useState([])
 const [max, setMax] = useState()

 const cek = () => {
  if (id > 0) {
   ULanding.findSubMenu(id, token).then((res) => {
    // console.log(res.data.menu);
    setData(res.data)
    // setDevOpt([{value: res.data.menu, label: res.data.menu}]);
    setDevOpt([{ value: res.data.link, label: res.data.menu }]);
    Field['menu'] = res.data.menu;
    Field['subMenu'] = res.data.subMenu;
    Field['link'] = res.data.link;
   })
  } else {
   setMax(props.data.max)
   Field['urut'] = props.data.max;
  }
 }
 const getMenu = async () => {
  ULanding.getAllMenu().then(async (res) => {
   const ops = []
   for (let row = 0; row < res.data.length; row++) {
    let op = { value: res.data[row].link, label: res.data[row].menu }
    // setOpt({value: res.data.menu, label: res.data.menu})
    ops.push(op)
   }
   setOpt(ops)
  })
 }
 const handleSubmit = (e) => {
  e.preventDefault()
  if (mdl === 'add') {
   ULanding.addSubMenu(Field, token).then((res) => {
    props.feedBack(res.data)
   }).catch((error) => {
    props.feedBack({ stat: 0, msg: error })
   })
  } else if (mdl === 'upd') {
   Field['id'] = id;
   ULanding.updSubMenu(Field, token).then((res) => {
    props.feedBack(res.data)
    console.log(res.data);
   }).catch((error) => {
    props.feedBack({ stat: 0, msg: error })
    console.log(error);
   })
  } else if (mdl === 'del') {
   Field['id'] = id;
   ULanding.delSubMenu(Field, token).then((res) => {
    props.feedBack(res.data)
    console.log(res.data);
   }).catch((error) => {
    props.feedBack({ stat: 0, msg: error })
   })
  }

 }

 const handleMenu = (e) => {
  // setDevOpt(false)
  setDevOpt(e)
  Field['menu'] = e.value
 }

 const handleChange = async (e) => {
  var target = e.target;
  var name = target.name;
  var value = target.value;
  Field[name] = target.value;
 }

 useEffect(() => {
  cek()
  getMenu()
 }, [])

 return (
  <>
   <form onSubmit={handleSubmit}>
    <Modal.Header closeButton>
     <Modal.Title>Modal {mdl}</Modal.Title>
    </Modal.Header>
    {mdl === 'add' || mdl === 'upd' ?
     (
      <>
       <Modal.Body>
        <div class="row">
         <div class="col-sm-12">
          <div class="form-group">
           <label>Menu</label>
           <Select options={opt} name="menu" value={devOpt ? devOpt : null} onChange={(e) => handleMenu(e)} />
          </div>
         </div>
         <div class="col-sm-12">
          <div class="form-group">
           <label>SubMenu</label>
           <input type="text" name="subMenu" defaultValue={data ? data.subMenu : null} class="form-control" onChange={(e) => handleChange(e)} />
          </div>
         </div>
         <div class="col-sm-12">
          <div class="form-group">
           <label>Link</label>
           <input type="text" name="link" defaultValue={data ? data.link : null} class="form-control" onChange={(e) => handleChange(e)} />
          </div>
         </div>
         <div class="col-sm-12">
          <div class="form-group">
           <label>Urutan</label>
           <input type="text" name="urut" defaultValue={data ? data.urut : max} class="form-control" onChange={(e) => handleChange(e)} />
          </div>
         </div>
        </div>
       </Modal.Body>
      </>
     )
     : (
      <>
       <Modal.Body>
        <div class="col-12">
         <p>Yakin akan delete ?</p>
        </div>
       </Modal.Body>
      </>
     )}
    <Modal.Footer>
     <button type='submit' className='btn bg-info' onClick={(e) => handleSubmit(e)}>
      <i class="fas fa-plus text-white"> Save</i>
     </button>
    </Modal.Footer>
   </form>
  </>
 )
}