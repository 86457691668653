import React from 'react'
import Header from '../component/global/Header'
import SidebarUser from '../component/global/SidebarUser'
import CarouselComp from '../component/global/Carousel'
import NewsComp from '../component/global/News'
import { useNavigate } from 'react-router-dom'
import Footer from '../component/global/Footer'

export default function LandingPage() {
 const navigate = useNavigate()
 return (
  <div className='main-wrapper'>
   <Header />
   <div className='page-wrapper'>
    <div className='container-lg'>
     <CarouselComp />
     <div className='row'>
      <div className='container-lg'>
       <a className='btn btn-lg btn-menu mt-2 mb-2 text-white btn-text' href="/register">
        Register
       </a>
      </div>
     </div>
     <NewsComp />
    </div>
   </div>
   <Footer />
  </div>
 )
}