import React, { useState, useEffect } from 'react';

import Header from '../component/global/Header'
import SidebarUser from '../component/global/SidebarUser'
import CarouselComp from '../component/global/Carousel'
import NewsComp from '../component/global/News'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ULanding from '../Utils/ULanding';
import UContent from '../Utils/UContent'
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import $ from 'jquery'

export default function ContentPage() {
 const [searchParams] = useSearchParams()
 const id = searchParams.get('id');
 const menu = searchParams.get('mn');
 const nm = searchParams.get('nm');
 const [SubMenu, setSubMenu] = useState()
 const [Content, setContent] = useState()
 const [Choise, setChoise] = useState()

 const navigate = useNavigate()

 function createMarkup(html) {
  return {
   __html: DOMPurify.sanitize(html)
  }
 }

 const getMenu = async () => {
  try {
   ULanding.getSubMenu(menu).then((res) => {
    setSubMenu(res.data)
    getContent(res.data[0].link)
   })
  } catch (error) {
   console.log(error);
  }
 }

 const getContent = async (data) => {
  try {
   ULanding.getContent(id).then((res) => {
    setContent(res.data[0].content)
    // $('table').removeAttr('style')
   })
  } catch (error) {
   console.log(error);
  }
 }

 const handleContent = (e) => {
  e.preventDefault()
  const target = e.target
  const url = target.getAttribute('href')
  const id = target.getAttribute('id')
  const a1 = url.split('&')
  const a2 = a1[1].split('=')
  setChoise(a2[1])
  getContent(a2[1])
  var actv = "#" + target.getAttribute('id')
  $('.nav-link').removeClass('active');
  setTimeout(() => {
   $(actv).addClass('active')
  }, 1000);
 }

 useEffect(() => {
  getMenu()
  getContent()
 }, [])
 return (
  <div className='main-wrapper'>
   <Header />
   <div className='page-wrapper'>
    <div className='container-lg'>
     <div className='row'>
      <div className='container-lg'>
       <a className='btn btn-lg btn-menu mt-2 mb-2 text-white btn-text' href="/register">
        Register
       </a>
       <div className='card'>
        <div className='card-header header-content'>
         <h3 style={{ textShadow: '1px 1px #f2f7f8', color: '#fff' }}>{nm}</h3>
        </div>
        <div className='card-body'>
         <div class="row">
          <div class="d-none d-sm-block col-sm-2">
           <ul class="nav nav-pills nav-fill flex-column">
            {SubMenu ? SubMenu.map((row) => {
             if (row.link === id) {
              return (
               <li class="nav-item">
                <a class="nav-link pills-text active" aria-current="page" href={'/content?id=' + row.link + '&mn=' + menu}>{row.subMenu}</a>
               </li>
              )
             } else {
              return (
               <li class="nav-item">
                <a class="nav-link pills-text" href={'/content?id=' + row.link + '&mn=' + menu}>{row.subMenu}</a>
               </li>
              )
             }
            }) : null}
           </ul>
          </div>
          <div className='col-12 col-sm-9 flex-wrap overflow-auto'>
           <div className='ck-content'>
            {Content ? (
             <div dangerouslySetInnerHTML={createMarkup(Content)}></div>
            ) : null}
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 )
}