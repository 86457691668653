import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ULanding from '../../../Utils/ULanding'
import { toast } from 'react-toastify';

export default function SidebarAdmin() {
 const [Menu, setMenu] = useState()
 const navigate = useNavigate
 const getMenu = async () => {
  setInterval(() => {
   try {
    ULanding.getAllMenu().then((res) => {
     setMenu(res.data)
    })
   } catch (error) {
    console.log(error);
   }
  }, 5000);
 }

 const Logout = (e) => {
  sessionStorage.removeItem('user')
  sessionStorage.removeItem('token')
  toast.success("Bye", {
   position: toast.POSITION.TOP_RIGHT
  })
  setTimeout(() => {
   navigate('/')
  }, 3000);
 }

 useEffect(() => {
  getMenu()
 }, [])
 return (
  <>
   <div className='container-fluid'>
    <ul class="navbar-nav me-auto my-lg-0 flex-row justify-content-between" style={{}}>
     <li class="nav-item btn-admin">
      <a class="nav-link active text-white txt-menu" aria-current="page" href="/dashboard">Home</a>
     </li>
     <li class="nav-item dropdown btn-admin">
      <a class="nav-link dropdown-toggle text-white txt-menu" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
       Landing
      </a>
      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
       <li><a class="dropdown-item txt-menu" href='/AdminCarousel'>Carousel</a></li>
       <li><a class="dropdown-item txt-menu" href='/AdminNews'>News</a></li>
       <li><a class="dropdown-item txt-menu" href='/AdminRegister'>Register</a></li>
      </ul>
     </li>
     <li class="nav-item dropdown btn-admin">
      <a class="nav-link dropdown-toggle text-white txt-menu" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
       Config
      </a>
      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
       <li><a class="dropdown-item txt-menu" href='/Menu'>Menu</a></li>
       <li><a class="dropdown-item txt-menu" href='/SubMenu'>SubMenu</a></li>
      </ul>
     </li>
     {
      Menu ? Menu.map((row) => {
       if (row.subMenu.length > 0) {
        return (
         <li class="nav-item dropdown btn-admin">
          <a class="nav-link dropdown-toggle text-white txt-menu" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           {row.menu}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
           {row.subMenu.map((rows) => {
            return (
             <li><a class="dropdown-item txt-menu" href={'/Admincontent?id=' + rows.link}>{rows.subMenu}</a></li>
            )
           })}
          </ul>
         </li>
        )
       } else {
        return (
         <>
          <li class="nav-item btn-admin">
           <a class="nav-link text-white" href={'/Admincontent?id=' + row.link}>{row.menu}</a>
          </li>
         </>
        )
       }
      }
      ) : null}
    </ul>
   </div>
  </>
 )
}