import React from 'react';
import TbNews from '../../component/global/admin/Table/TbNews';
import Header from '../../component/global/Header';
import SidebarAdmin from '../../component/global/admin/SidebarAdmin';
import HeaderAdmin from '../../component/global/HeaderAdmin';

export default function NewsScreen() {
 return (
  <>
   <HeaderAdmin />
   <div className='page-wrapper'>
    <div className='container-fluid'>
     <TbNews />
    </div>
   </div>
  </>
 )
}