import React, { useState, useEffect } from 'react';
import UNews from '../../../../Utils/UNews';
import DOMPurify from 'dompurify';
import { Modal } from 'react-bootstrap';

export default function ModalUserNews(props) {
 const token = sessionStorage.getItem('token');
 const [news, setNews] = useState()

 function createMarkup(html) {
  return {
   __html: DOMPurify.sanitize(html)
  }
 }

 const getNews = () => {
  try {
   UNews.findNews(props.id, token).then((res) => {
    setNews(res.data)
   })
  } catch (error) {
   console.log(error);
  }
 }

 const handleClose = () => {
  props.feedBack('close')
 }

 useEffect(() => {
  getNews()
 }, [])

 return (
  <>
   {news ?
    <>
     {/* <Modal.Header closeButton>
      <Modal.Title></Modal.Title>
     </Modal.Header> */}
     <Modal.Body>
      <div
       className="ck-content"
       dangerouslySetInnerHTML={createMarkup(news.activity)}>
      </div>
     </Modal.Body>
     <Modal.Footer>
      <button className='btn-menu text-white' onClick={handleClose}>Close</button>
     </Modal.Footer>
    </>
    : null
   }
  </>
 )
}